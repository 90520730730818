@import './../../styles/scss/_variables.scss';

form {
    .p-inputtext {
        padding: 0.5rem 0.25rem .5rem .5rem !important;
        box-shadow: inset 0 0 0 0.05rem $border !important;
    }
}


.form {
    //min-height: 14rem;
    label.review,
    span.review {
        display:inline-block;
        position: relative;
        top: -4rem;
        //min-height: 2.5rem;
    }
}

label.p-error + span + div.p-password{
    box-shadow: 0 0 0 0.05rem #d0737f;
}
.form-message:not(.review-message) {
    height: 2rem;
    text-align: center;
}

.success {
    color: $success-color;
}

.error {
    color: $error-color;
}

.review-mode {
    .review-message {
        font-weight: 500 !important;
        font-size: 1rem !important;
        display: flex;
        justify-content: center;
        height: 2rem;
    }
    
    .field.password span {
        label[for='password'] {
            margin-left: 8.5rem; 
        }
    }

    .p-inputtext, .p-password-input {
        background: transparent !important;
        padding-left: 0;
        &:enabled:focus {
            box-shadow: none !important;
            background: transparent !important;
        }
        &:enabled:hover {
            box-shadow: none !important;
            background: transparent !important;
        }
        &:hover {
            box-shadow: none !important;
            background: transparent !important;
        }
    }
    .asterisk {
        display: none;
    }
}
