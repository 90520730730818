.p-inputtext:not(.p-dropdown-label):not(.search):not(.p-invalid) {
    color: $text;
    border: 0 !important;
    padding: 0.25rem 0.25rem .25rem .5rem;
}

.p-inputtext.search {
    padding: 0.5rem 0.5rem 0.5rem 2rem;
}

textarea {
    width: 100%;
    background: $input-background;
    color: $text;
    &.p-invalid {
        box-shadow: inset 0 0 0 0.5px $required-color !important;
    }
}

.p-inputtext:not(.readonly .p-inputtext):not(.p-dropdown .p-dropdown-label) {
    background: transparent;
}

.readonly {
    .p-inputtext, .p-inputtext:hover, .p-inputtext:focus {
        border: 0 !important;
        background: transparent !important;
        box-shadow:none !important;
    }
}

.panel {
    .p-inputtext:read-only:not(form input),
    .p-inputtext:enabled:focus:not(form .p-inputtext:enabled:focus) {
        border: 0 !important;
        background: transparent !important;
        box-shadow:none !important;
    }
}

input.p-invalid {
    box-shadow: inset 0 0 0 0.5px $required-color !important;
}

.p-invalid > p-password-input,
.p-checkbox.p-invalid > .p-checkbox-box {    
    box-shadow: 0 0 0 1px $required-color !important;
}

.p-inputtext:focus,
.p-inputtext:enabled:focus,
.p-inputtext:not(.readonly .p-inputtext):not(.p-dropdown .p-dropdown-label):not(.p-inputtext:read-only):not(form .p-inputtext) {
	box-shadow: inset 0 0 0 .05rem $border;
}

input:focus-visible {
    outline: 0;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: $primary;
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
    background: $primary;
}

.p-inputswitch.p-focus .p-inputswitch-slider {
    box-shadow: none;
}

.p-inputswitch .p-inputswitch-slider {
    background: $input-switch-bg;
}

.p-password-panel {
    background: $background;
}

.p-multiselect
, .p-multiselect-panel
, .p-multiselect-panel .p-multiselect-header
, .p-multiselect-header {
    background: $background;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.1rem $border;
}
.p-multiselect:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.1rem $border;
    border-color: $border;
}