@import 'primereact/resources/themes/vela-purple/theme.css';
@import "primereact/resources/primereact.min.css";
@import '/node_modules/primeflex/primeflex.css';
@import 'primeicons/primeicons.css';
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;500;600&family=Urbanist:wght@300;400&display=swap');    //&family=Sono:wght@300;400

$font: 'Jost';
$card-text-font: 'Jost';
$data-font: 'Jost';
$data-font-weight: 400;
$font-weight:400;
$border-radius:.5rem;
$transitionDuration:.2s;
$font-size:.8vw;
$footer-height:0%; //5.5%

$base: #1d1e24;
$dustypurple: #472c63;
$white: #fff;
$bg: #463fa1;
$primary:  #756cef; //756cef;   //6c6fef;  //72E5FFE8  //00D0FF   //
$primary-light: lighten($primary, 10%);
$primary-dark: darken($primary, 5%);   // = 006880e8
$primary-hover: $primary-light;
$accent: #bada55;    //#afa9fd;    //ffa600; //96ff33b4;   //91ce72
$accent-light: lighten($accent, 25.5%);
$accent-dark: darken($accent, 30%);
$dropp: #27c3f0;

$text: #dddddd;
$dirty-text: #b1b1b1;
$wolf: #b8c1c5;
$required-color: #ed6f6f;
$false-color: #ed6f6f;
$true-color: #6fed80;
$info-color: #6fbaed;
$warning-color: #fe8d59;
$error-color: #fc5347;
$critical-color: #fc5347;
$callout: $accent;
$title: $accent;
$border:lighten($base, 10%);

$highlight-light: #ffffff1a;
$highlight-dark: #ffffff00;
$input-border: #4d505f;
$row-border: #37393f;                           //3b3e49
$input-background: darken($base, 2.5%);
$input-border: lighten($input-background, 40%);
$input-background-hover: lighten($input-background, 5%);

$success-color: $true-color;
$positive: $true-color;
$negative: $false-color;
$increment: $true-color;
$decrement: $false-color;
$scrollbar: lighten($base, 20%);
$scrollbar-background:#4a4c53;
$scrollbar-track: darken($scrollbar-background, 12.5%);

$icon: $text;
$background: $base;
$background-dark: #161616;
$background-border: darken($background, 7.5%);
$background-border-light: lighten($background, 10%);

$sidebar: $background;
$sidebar-border: $background-border;
$section: lighten($background-dark, 0%);
$section-border: #1a1a1a;
$card: lighten($background, 1.5%);
$card-text: $dirty-text;
$card-border: lighten($card, 3%);
$chart-bg: $base;
$card-title: darken($dirty-text, 10%);

$overlay: lighten($background, 1.5%);
$overlay-border: lighten($overlay, 5%);
$glass: #4e4e4e40;
$glass-border: 1px solid #ffffff42;

$link: $accent;
$link-hover: $accent-light;
$nav-icon: $text;
$nav-item-border: lighten($sidebar, 10%);

$table-head: #1c1d22;
$table-foot: $table-head;
$table-odd-row: #1c1d22;
$column-resizer: #303030;

$button-bg: lighten($base, 7.5%);    //34363d
$button-bg-hover: lighten($button-bg, 3%);
$button-box-shadow:none;
$button-border:lighten($button-bg, 7.5%);
$button-press: darken($text, 15%);
$input-switch-bg: lighten($button-bg, 5%);

$menu-item-border: $border;
$network: $primary;;
$staking: $accent;
$separator:lighten($border, 10%);

$accordion-tab-active: #1f2024;

$chart1:#003f5c;
$chart2:#2f4b7c;
$chart3:#665191;
$chart4:#a05195;
$chart5:#d45087;
$chart6:#f95d6a;
$chart7:#ff7c43;
$chart8:#ffa600;

$chart1:#6a4f88;    //cbabfc
$chart2:#7698fe;
$chart3:#2f4b7c;
$chart4:#bf94ff;
$chart5:#a05195;
$chart6:#5fccb7;;
$chart7:#8d3d54;
$chart8:#9072b3;

// Original Base Colors
$chart1: #003f5c;
$chart2: #2f4b7c;
$chart3: #665191;
$chart4: #a05195;
$chart5: #d45087;
$chart6: #f95d6a;
$chart7: #ff7c43;
$chart8: #ffa600;
$chart9: #2DD4BF;

:root {
        // First cycle (original colors randomized)
        --chart1: #f95d6a;
        --chart2: #003f5c;
        --chart3: #ffa600;
        --chart4: #2f4b7c;
        --chart5: #ff7c43;
        --chart6: #665191;
        --chart7: #d45087;
        --chart8: #a05195;
        --chart9: #2DD4BF;
    
        // Second cycle (randomized)
        --chart10: #ff8752;
        --chart11: #004b6d;
        --chart12: #ff6879;
        --chart13: #3f5789;
        --chart14: #3bd3bf;
        --chart15: #ffb010;
        --chart16: #765ca0;
        --chart17: #e45c96;
        --chart18: #b05ca4;    
        
        // Third cycle (randomized)
        --chart19: #ff7388;
        --chart20: #005777;
        --chart21: #ffba20;
        --chart22: #4f6399;
        --chart23: #ff9261;
        --chart24: #8667b0;
        --chart25: #41bbab;
        --chart26: #f467a5;
        --chart27: #c067b3;
        
        // Fourth cycle (randomized)
        --chart28: #ff7e97;
        --chart29: #006385;
        --chart30: #3a8379;
        --chart31: #ffc430;
        --chart32: #5f6fa9;
        --chart33: #ff9d70;
        --chart34: #9672c0;
        --chart35: #ff72b4;
        --chart36: #d072c2;
        
        // Fifth cycle (randomized)
        --chart37: #ff89a6;
        --chart38: #006f93;
        --chart39: #ffce40;
        --chart40: #6f7bb9;
        --chart41: #ffa87f;
        --chart42: #a67dd0;
        --chart43: #ff7dc3;
        --chart44: #e07dd1;
        --chart45: #458179;
    
        --error: #{$error-color};
        --success: #{$success-color};
        --dirty-text:#{$dirty-text};
        --chart-bg:#{$background-dark};
  }

$knob: #ffa600;
$knob-background: lighten($base, 20%);
$knob-text-size: 0;

$sphere: #0e1b27;
$country: #879ccb;
$country-border: #0e1b27;
$graticule: #626262;

$marker: $chart8;
$marker-selected: $chart6;
$marker-shadow: #000000b3;
$marker-inactive: #acacac;