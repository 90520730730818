.p-tabview.outer {    
    .p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
        background: unset;
        border-color: unset;
    }
    .p-tabview-panels {
        padding: 0.75rem 1rem 0.25rem 0;
        overflow-y: auto;
    }
    .p-tabview-nav-link:not(.p-tabview.inner .p-tabview-nav-link) {
        cursor: default;

        .p-tabview-title:before {
            margin-right: .5rem;
        }
        .p-tabview-title:after {
            margin-left: .5rem;
        }

        .p-tabview-title:after,
        .p-tabview-title:before {
            background-color: $primary;
            border-radius: 0.25rem;
            content: "";
            display: inline-block;
            height: 0.1rem;
            width: 3rem;
            margin-bottom: 0.33rem;
            
        }
    }
    .p-tabview-nav li .p-tabview-nav-link {
        padding: 0.75rem 1rem 0.25rem 1.25rem;
    }
}

.p-tabview.inner {
    padding: 0 0 0 1rem;
    .p-tabview-nav {
        li:not(.p-tabview-selected) {
            border-bottom: 1px solid transparent;
        }
    }
    .p-tabview-nav {
        .p-tabview-nav-link {
            cursor: pointer;
            padding: 0.5rem 1rem 0.5rem !important;
        } 
    }
    
    .p-tabview-selected {
        border-bottom:1px solid $primary !important;
    }

    li:hover:not(.no-hover) {
        border-bottom: 1px solid $primary;
    }
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    box-shadow: none;
}

.p-tabview-title {
    color: $dirty-text
}

.p-tabview-selected {
    .p-tabview-title {
        color: $text
    }
}