
.pi {
    color: $icon;
}

.pi.callout {
    color: $callout;
}

.p-icon {
    width:1rem;
}

.icon {
    color: $icon;
}

.icon.primary {
    background: $icon !important;
}

.primary + i {
    color: $icon;
}

.icon.icon-warning {
    mask-image: url(./../images/warning-sign.svg);
}

.icon.icon-twitter-x {
    mask-image: url(./../images/twitter-x.svg);
    background: #dddddd;
    mask-repeat: no-repeat;
}

// .pi.selected {
//     color: $primary-light;
// }

// .icon.selected {
//     background: $primary-light;    
// }

// .pi.success {
//     color: $success-color;
// }

.pi-exclamation-triangle,
.pi.warning {
    color: $warning-color;
}

.pi.info {
    color: $info-color
}

// .p-dropdown-clear-icon {
//     margin-right: .5rem;
// }

.substack-watermark {
    mask-image: url('./../images/substack_wordmark.png');
    width: 68px;
    height: 16px;
    // padding: 10rem 0 0 0;
}
.p-input-icon-left > i, 
.p-input-icon-right > i, 
.p-input-icon-left > svg, 
.p-input-icon-right > svg, 
.p-input-icon-left > 
.p-input-prefix, 
.p-input-icon-right > 
.p-input-suffix {
    position: absolute;
    top: 50%;
}

.lucide-icon {
    height: 1.333rem;
    margin-right: .5rem;
    &.menu-item.selected {
        color: $primary-light;
    }
    &:hover {
        color: $primary-light;
    }
}