.p-toolbar {
    padding: 0;
}

.toolbar-container {
  position: relative;

}

.toolbar-scroll-container {
  overflow-y: auto;

}

/* Style for the main toolbar content */
.main-toolbar {
  padding: 0 0.75rem 0 1.25rem;
  z-index: 8;
}

/* Style for the fixed end content */
.toolbar-end-fixed {
  position: fixed;
  top: 4.45rem;
  right: .333rem;
  z-index: 9;
  background: transparent;
  padding: 0.5rem;
}