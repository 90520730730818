
.p-dropdown-item-group {
    //background: $surface-20 !important;
    font-weight: 500 !important;
    // font-size: 1.075em
}

.p-dropdown-items {
    background: $background;
    .p-dropdown-item {
        padding: .5rem !important;
    }
}

.p-dropdown-item-group ~ .p-dropdown-item {
    padding-left: .5rem !important;
}

.p-dropdown:not(.p-disabled).p-focus,
.p-dropdown:not(.p-disabled):hover {
    border-color: $border !important;
    box-shadow: none;
}

.p-dropdown-label.p-inputtext {
    background: $input-background;
    // padding: 0.75rem 0.5rem;
}

.p-dropdown-header {
    background: $overlay !important;
}

.p-dropdown-item {
    font-family: $font;
}

.p-paginator .p-dropdown .p-dropdown-label {
    padding: .5rem .75rem;
}

.p-dropdown-label.p-inputtext.p-placeholder {
    visibility: hidden;
}