    @import './../styles/scss/_variables.scss';

    header {
        display:block;
        width: 100%;
        padding: .6rem 1.5rem !important;
        background: $background;
        border-bottom: .5px solid $sidebar-border !important;
        
        button.login span {
            font-size: 1.0rem !important;
        }

        .app-name {
            font-family: 'Urbanist';
            font-weight: 300;
            color: $wolf;
            display:flex;
            align-items:center;
            background-position-x: left;
            background-position-y: center;
            background-repeat:no-repeat;
            background-size: 17.5rem;
            font-size:2rem !important;
            white-space: nowrap;
            &.mobile {
                justify-content: center;
            }
        }        
        
        // .powered-by {
        //     img {
        //         width: 100%;
        //         object-fit: cover;
        //     }
        // }
    }

    footer {
        width: 100%;
        background: $background;
        bottom: 0 !important;
        position: absolute;
        z-index: 999;
        border-top: .5px solid $sidebar-border !important;
        height: 2rem;
        margin: auto;
        display:none !important;
    }

    .h-logo{
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width:3.5rem;
        height:3.5rem;
        overflow: hidden;
        border-radius: 50%;
        background-image: linear-gradient(transparent, lighten($primary, 5%) , transparent, lighten($primary, 5%));
    }

    .h-logo::before{
        content: "";
        position: absolute;
    }

    .h-logo::after{
        content: "";
        position: absolute;
    }

    .h-logo span{
        position: absolute;
        inset: .45rem;
        background-color: #080909;
        border-radius: 50%;
        z-index: 1;
    }
    
    .h-logo div {
        z-index: 1;
        font-size: 2.25em !important;
        color: $wolf;
        font-family: 'Urbanist' !important;
    }
    
    .is-metrics-false {
        display:none !important;
    }

    #access_panel {
        .p-overlaypanel-content {
            padding: 1.5rem;
        }
    }

    #countdown-wrapper.mobile {
        >div {
            display: grid;
            justify-content: center;
        }
    }