@import './../../styles/scss/_variables.scss';

// Variables - Typography
$terms-font-family: 'Jost', 'Urbanist' !default;
$terms-font-size-base: 2rem !default;
$terms-line-height-base: 1.5 !default;

$terms-font-size-h1: 1.0rem !default;
$terms-font-size-h2: 1.25rem !default;
$terms-font-size-h3: 1.333rem !default;
$terms-font-size-small: 0.875rem !default;

// Variables - Colors
$terms-color-text: #b4b4b4 !default;
$terms-color-heading: #b4b4b4 !default;
$terms-color-link: #60a5fa !default;
$terms-color-link-hover: #93c5fd !default;
$terms-color-border: #374151 !default;

// Variables - Spacing
$terms-spacing-base: 1rem !default;
$terms-spacing-large: 2rem !default;
$terms-spacing-small: 0.5rem !default;

// Variables - Container
$terms-max-width: 64rem !default;
$terms-padding-container: 2rem !default;

// Main Terms Container
.terms {
  font-family: $terms-font-family;
  font-size: $terms-font-size-base;
  line-height: $terms-line-height-base;
  color: $terms-color-text;
  max-width: $terms-max-width;
  margin: 0 auto;
  padding: $terms-padding-container;

  // Header Styles
  &__header {
    text-align: center;
    margin-bottom: $terms-spacing-large * 2;
  }

  &__title {
    font-size: $terms-font-size-h1 !important;
    color: $terms-color-heading;
    font-weight: 700;
    margin-bottom: $terms-spacing-base;
  }

  &__date {
    font-size: $terms-font-size-base;
    color: $terms-color-text;
  }

  // Section Styles
  &__section {
    margin-bottom: $terms-spacing-large;
  }

  &__section-title {
    font-size: $terms-font-size-h3 !important;
    color: $terms-color-heading;
    font-weight: 500;
    margin-bottom: $terms-spacing-base;
  }

  &__subsection-title {
    font-size: $terms-font-size-h2 !important;
    color: $terms-color-heading;
    font-weight: 400;
    margin-bottom: $terms-spacing-base;
  }

  // Content Styles
  &__content {
    margin-bottom: $terms-spacing-base;

    p {
      margin-bottom: $terms-spacing-base;
    }

    ul, ol {
      margin-bottom: $terms-spacing-base;
      padding-left: $terms-spacing-large;

      li {
        margin-bottom: $terms-spacing-small;
      }
    }

    ul {
      list-style-type: disc;
    }

    ol {
      list-style-type: decimal;
    }
  }

  // Link Styles
  &__link {
    color: $terms-color-link;
    text-decoration: none;
    transition: color 0.2s ease;

    &:hover {
      color: $terms-color-link-hover;
    }
  }

  // Utility Classes
  &__text-small {
    font-size: $terms-font-size-small;
  }

  &__text-center {
    text-align: center;
  }

  &__font-bold {
    font-weight: 700;
  }

  &__font-medium {
    font-weight: 500;
  }

  // Responsive Adjustments
  @media (max-width: 768px) {
    padding: $terms-padding-container / 2;

    &__title {
      font-size: $terms-font-size-h1 * 0.875;
    }

    &__section-title {
      font-size: $terms-font-size-h2 * 0.875;
    }

    &__content {
      font-size: $terms-font-size-base * 0.9375;
    }
  }

  // Print Styles
  @media print {
    color: #000;
    
    &__title,
    &__section-title,
    &__subsection-title {
      color: #000;
    }

    &__link {
      color: #000;
      text-decoration: underline;
    }
  }
}

// Accessibility
@media (prefers-reduced-motion: reduce) {
  .terms {
    * {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }
}